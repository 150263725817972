<template>
  <div class="dsp-container">
    <messageBar
      :show="showBlueBar"
      :page-type="pageName"
      @hideAction="hideBlueBar"
    />
    <div v-if="openActivity">
      <activity-log
        v-if="showActionsLog"
        :data="activityLogData"
        :page-name="pageName"
        :close-activity-log="closeActivityLog"
        :top-spacing="'0px'"
      />
    </div>
    <Header
      :entity-id="entityId"
      :entity-config="entityConfig && entityConfig.header"
      @filterMounted="handleFilterMount"
    />
    <div v-if="dateFilter">
      <div v-if="entityConfig.chart">
        <chart-wrapper
          :entity-id="entityId"
          :entity-config="entityConfig && entityConfig.chart"
          :date-filter="dateFilter"
          @tabsMetadata="tabsMetadata"
        />
      </div>
      <TabsComponent
        v-if="tabMetadata.show"
        :tabs-metadata="tabMetadata"
        :entity-config="entityConfig"
        :entity-id="entityId"
        :date-filter="dateFilter"
      />
    </div>
  </div>
</template>
<script>
import config from '@/pages/entity_details/configs/index.js';
import { eventBus } from '@/utils/services/eventBus';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import utils from '@/utils/helpers/';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
const Header = () =>
  import('@/pages/entity_details/common_components/header/dspHeader.vue');
const TabsComponent = () =>
  import('@/pages/entity_details/common_components/dspTabsComponent.vue');
const chartWrapper = () =>
  import('@/pages/entity_details/common_components/dspChartWrapper.vue');
const activityLog = () =>
  import('@/components/widgets/collaboration/collaboration.vue');

export default {
  name: 'DspEntityDetails',
  components: {
    Header,
    chartWrapper,
    TabsComponent,
    activityLog,
    messageBar
  },
  data() {
    return {
      entityConfig: null,
      dateFilter: null,
      tabMetadata: {
        show: false,
        data: null
      },
      entityId: null,
      showActionsLog: false,
      openActivity: false,
      activityLogData: {
        load: true,
        rows: null
      },
      pageName: '',
      showBlueBar: false,
      timer: 30000
    };
  },
  created() {
    const { entityId, entityType = 'dsp' } = this.$route.params;
    this.init(this.$store.getters.getRetailer, entityType, entityId);
    this.setDateRange();
    eventBus.$on(this.entityConfig?.header?.filters?.config?.emit, (data) => {
      this.setDateRange(data);
    });
    eventBus.$on('entityOpenActivityLog', ({ name, ...rest }) => {
      this.pageName = name;
      this.openSidePanel(rest);
    });
    eventBus.$on('entityBlueBar', (name) => {
      this.pageName = name;
      this.switchBlueBar();
    });
  },
  beforeDestroy() {
    eventBus.$off('entityOpenActivityLog');
    eventBus.$off('entityBlueBar');
  },
  methods: {
    init(retailer, entity_type, entity_id) {
      const path = this.$route.path;
      const entity = Object.keys(config[retailer][entity_type]).find((e) =>
        path.includes(e)
      );
      this.entityConfig = config[retailer][entity_type][entity];
      this.entityId = entity_id;
      this.callMetadata(entity_id);
    },
    callMetadata(entity_id) {
      for (const item of this.entityConfig.metadata) {
        if (item?.action) {
          let payload = '';
          if (item?.getApiData) {
            payload = {
              APIData: item.getApiData(entity_id),
              storeActionName: item.storeActionName,
              name: this.entityConfig.name
            };
          } else {
            payload = entity_id;
          }
          this.$store.dispatch(item.action, payload);
        }
      }
    },
    tabsMetadata(tabsData) {
      // add custom logic for tabs metadata (pages) here - > dsp
      this.tabMetadata = {
        show: true,
        data: tabsData.metadata
      };
    },
    handleFilterMount() {
      // used to set date filters only during first load
      if (!this.dateFilter) {
        this.setDateRange();
      }
    },
    setDateRange(filterData) {
      let dateFilter = filterData;
      if (!filterData) {
        const key = this.entityConfig?.header?.filters?.filterLocalStorageKey;
        dateFilter = JSON.parse(localStorage.getItem(key))?.date_range || null;
      }
      this.dateFilter = dateFilter && {
        ':to': dateFilter.to,
        ':from': dateFilter.from,
        ':pvpTo': dateFilter.compare_to,
        ':pvpFrom': dateFilter.compare_from
      };
    },
    openSidePanel(data) {
      if (data) {
        this.showActionsLog = true;
        this.openActivity = true;
        const res = {};
        HttpService.get('AMS_ACTIONS', data)
          .then((response) => {
            res.data = response.data.response;
          })
          .catch((error) => {
            res.error = error;
          })
          .finally(() => {
            if (res.error) {
              this.activityLogData = {
                load: false,
                error: res.error
              };
              return;
            }
            this.activityLogData = {
              load: false,
              ...(res.data.length
                ? { rows: activitytransformer.getActivityData(res.data) }
                : { noData: true })
            };
          });
      }
    },
    closeActivityLog() {
      this.showActionsLog = false;
      this.openActivity = false;
      this.activityLogData = {
        load: true,
        rows: []
      };
    },
    hideBlueBar() {
      this.showBlueBar = false;
    },
    switchBlueBar() {
      setTimeout(() => {
        this.showBlueBar = true;
      }, this.timer);
    }
  }
};
</script>

<style lang="css">
.charting_wb_wrapper .custom_widget_body {
  margin-bottom: 2.4rem;
}
.dsp-container .u-envelop-width {
  max-width: initial;
}
</style>
